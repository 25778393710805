.about {
    width: 100%;
    height: 100dvh;
    color: white;
    background-color: #f36d21;

}

.about-title {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 5%;
}

.about-title>div {
    width: 25%;
    height: 60%;
    background-color: transparent;
    border: 10px solid white;
    font-size: clamp(1.5rem, 4vw, 4rem);
    font-weight: 700;
    display: grid;
    color: white;
    place-items: center;
}

.about-title>hr {
    width: 60%;
    height: 4px;
    background-color: white;
}

.about-body {
    height: 70%;
    width: 100%;
    display: flex;

}

.about-img {
    width: 50%;
    height: 100%;
    position: relative;
    box-sizing: border-box;

}

.about-img::before {
    content: '';
    width: 60%;
    height: 80%;
    position: absolute;
    z-index: 2;
    background-color: #ff914d;
    bottom: 4rem;
    left: 5rem;
}

.about-img::after {
    content: '';
    width: 60%;
    height: 80%;
    position: absolute;
    background-color: transparent;
    border: 10px solid #ffbd59;
    z-index: 3;
    top: 10%;
    left: 20%;
}

.about-img>img {
    height: 90%;
    width: 70%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    padding: 2%;
    box-sizing: border-box;
}

.about-text {
    width: 45%;
    box-sizing: border-box;
    padding: 0 4% 0 0;
    height: 100%;
    text-align: center;
}

.about-text>p {
    height: 80%;
    padding-bottom: 4%;
    overflow-x: hidden;
}

.about-text>p::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:700px) {
    .about-title>hr {
        display: none;
    }

    .about-img,
    .about-img>img {
        display: none;
    }

    .about-title {
        height: 30%;
        width: 100%;
        justify-content: flex-start;
        padding-left: 10%;
    }

    .about-title>div {
        width: 50%;
        height: 50%;
        position: relative;
    }

    .about-title>div::before {
        content: '';
        position: absolute;
        height: 70%;
        width: 50%;
        bottom: -60%;
        right: -40%;
        background-color: transparent;
        border: 7px solid #ff914d;
        z-index: 2;
    }

    .about-text {
        width: 80%;
        box-sizing: border-box;
        padding: 4% 4% 0 0;
        margin: 5% auto;
        height: 80%;
        box-sizing: border-box;
    }

    .about-text>p {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 4%;
        overflow-x: hidden;
        box-sizing: border-box;

    }

}
@media screen and (max-height:500px) {
    .about {
        width: 100%;
        height: 150dvh;
        color: white;
        background-color: #f36d21;
    
    }
}