.products {
    height: 100dvh;
    width: 100%;
    background-color: #f36d21;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}

.pro-left {
    width: 50%;
    height: inherit;
    display: grid;
    place-items: center;
    position: relative;
    z-index: 9;
}

.pro-left::before {
    content: '';
    height: 100%;
    width: 50%;
    background-color: #ff914d;
    position: absolute;
    top: 0;
    left: 0%;
    z-index: 0;
}

.pro-left::after {
    content: '';
    height: 80%;
    width: 100%;
    border: 10px solid white;
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 2;
}

.pro-img-cont {
    height: 100%;
    width: 80%;
    position: relative;
    z-index: 99;
    object-fit: cover;
    padding: 2%;
}

.pro-img {
    height: 100%;
    width: 100%;
    object-fit: fill;

}

.pro-right {
    width: 50%;
    height: inherit;
    box-sizing: border-box;
}

.pro-heading {
    height: 20%;
    width: 100%;
    font-size: clamp(1.5rem, 4vw, 4rem);
    font-weight: 700;
    color: white;
    display: grid;
    place-items: center;

}

.items-list-cont {
    height: 65%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    box-sizing: border-box;
    justify-content: space-evenly;
    
}

.items-list-cont::-webkit-scrollbar {
    display: none;
}

.item {
    background-color: #ffffff;
    height: 10rem;
    width: 10rem;
    margin: 2%;
    transition: all .2s;
    box-shadow: 2px 2px 10px 1px #ffffff;
    box-sizing: border-box;
    padding: 2%;
    mix-blend-mode: color-burn;
}

.item:hover {
    transform: scale(1.2);
    border-radius: 10px;
}

.button-area {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro-btn {
    height: 50px;
    width: 140px;
    background-color: #ff914d;
    border-style: none;
    color: white;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
}

.pro-btn:hover {
    color:#f36d21;
    font-weight: 600;
    background-color: white;

}

@media screen and (max-width:800px) {
    .products {
        height: 177dvh;
        flex-direction: column-reverse;

    }

    .pro-right {
        width: 100%;
        height: 60%;
    }

    .pro-left {
        width: 100%;
        height: 40%;
        place-items: center end;

    }

    .pro-left::before {
        content: '';
        height: 50%;
        width: 100%;
        position: absolute;
        top: 70%;
        left: 0%;
        z-index: 0;
    }

    .pro-left::after {
        content: '';
        height: 85%;
        width: 100%;
        position: absolute;
        top: 25%;
        left: 5%;
        z-index: 2;
    }

    .items-list-cont {
        height: 60%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow: scroll;
        box-sizing: border-box;
    }

    .button-area {
        height: 25%;
        padding-left: 5%;
        width: 100%;
        display: flex;
        align-items: center;
    }

}
@media screen and (max-height:500px) {
    .products {
        height: 150dvh;
     
    }
}