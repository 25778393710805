.contact {
    background: url(https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100dvh;
    background-attachment: fixed;
}

.contact>h1 {
    height: 15%;
    margin: 0;
    padding: 0;
    width: 50%;
    display: grid;
    padding-left: 5%;
    font-size: clamp(2rem, 4vw, 3.5rem);
    font-weight: 600;
    place-content: end start;
    color: #f36d21;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 0.5px;
}

.contact-form-container {
    background-color: transparent;
    display: flex;
    height: 80%;
    align-items: center;
}

.contact-form {
    max-width: 600px;
    width: 100%;
    padding-left: 5%;
    border-radius: 8px;
    height: 80%;
}

.contact-form input,
.contact-form textarea {
    height: 3rem;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f36d21;
    color: white;
    outline-style: none;

}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
    background-color: #f36d21;
    color: white;
}

.contact-form textarea {
    height: 50%;
}

.contact-form button {
    height: 3rem;
    width: 7rem;
    padding: 10px;
    background-color: #f36d21;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s;
}

.contact-form button:hover {
    background-color: #ff914d;
    font-size: 1.2rem;
}





.contact-right::after {
    content: '';
    height: 80%;
    width: 60%;
    background-color: transparent;
    border: 20px solid #b3afad;
    position: absolute;
    z-index: 0;
    left: 25%;
}

@media screen and (max-width: 480px) {
    .contact-form {
        max-width: 80%;
    }
}
@media  screen and (max-width:800px) {
    .contact-right {
      display: none;
    }
    .contact {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100dvh;
    }
    
}
@media  screen and (max-height:500px) {
    .contact {
        height: 130dvh;
    }
}