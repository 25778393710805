.team {
    height: fit-content;
    min-height: 90dvh;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
     display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.team>h1{
    height:5rem;
    width:100%;
    display: grid;
    place-content: center;
    box-sizing: border-box;
    font-size: clamp(2rem,4vw,4rem);
    color: #f36d21;

}
.team-cont{
    width: 100%;
    display: flex ;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.card{
    width: 20rem;
    margin: 1% 2%;
    height: 28rem !important;
    transition: all 1s !important;

}
.card-img-top{
    height: 55%;
    object-fit: cover;
    object-position: center;
}
.card-body{
    height:40%;
    background-color: #f36d21;

}
.card-text{
    height: 70%;
    overflow: scroll;
    box-sizing: border-box;
    transition: all .2s;
    color: white;
}
.card-text::-webkit-scrollbar{
    display: none;
}
.card-title{
    font-size: clamp(1.3rem,1.5vw,1.8rem);
    font-weight: 500;
    color: white;
}
.card:hover{
    height:fit-content;
    transform: translate(-2% ,-2%) !important;
    box-shadow: 5px 5px 10px 2px grey;
}
