#white{
    color: white !important;
}

.display-items{
    width: 100%;
    display: flex;
}
.items-head{
    width: 20%;
    height: fit-content;
    padding: 2%;
    margin: 2%;
    box-sizing: border-box;
    font-size: clamp(3rem,4vw,4rem);
    color: #f36d21;
    font-weight: 600;
    text-align: center;
}
.all-item-cont{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
}
@media screen and (max-width:600px) {
    .display-items{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .items-head{
        width: 100%;
     
    }
    .all-item-cont{
        width: 100%;
  
        
    }
}