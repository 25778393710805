.home{
    height: calc(100dvh - 4rem);
    width: 100%;
    background: url(https://www.nobroker.in/blog/wp-content/uploads/2022/05/Living-Room-Ceiling-Lights.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
}
.home-text{
    height: 100%;
    width: 100%;
    text-align: left;
    display: grid;
    place-content: center;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.394);
    color: white;
}
.home-text>h1,.home-text>h2,.home-text>h3{
    width: 60%;
    padding: 0 4% ;
    display: grid;
    place-content: center start;
}
.home-text>h1{
    font-size: clamp(3rem,5vw,5rem);
}
.home-text>h2{
    font-size: clamp(2rem,4vw,4rem);
    color: #f36d21;
}
.home-text>h3{
    font-size: clamp(1.2rem,2vw,2rem);
}
@media screen and (max-width:800px) {
    .home{
        height: calc(100dvh - 4rem);
        width: 100%;
        background-repeat: no-repeat;
    background: url(https://www.nobroker.in/blog/wp-content/uploads/2022/05/Living-Room-Ceiling-Lights.jpg);
        background-size: cover;
        background-position: center;
    }
    .home-text>h1,.home-text>h2,.home-text>h3{
        width: 100%;
        padding: 0 4% ;
        display: grid;
        place-content: center start;
    }
}

@media  screen and (max-height:500px) {
    .home{
        height: calc(120dvh - 4rem);
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}