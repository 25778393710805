.catelog {
    height: 100dvh;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    background-color: white;
    color: #070d10;
    backdrop-filter: blur(2px);
}

.cat-left {
    height: 100%;
    width: 60%;
    box-sizing: border-box;
}

.cat-heading {
    height: 15%;
    width: 100%;
    letter-spacing: 2px;
    font-size: clamp(2rem, 4vw, 4rem);
    font-weight: 700;
    color: #f36d21;
    display: grid;
    place-items: end center;
    box-sizing: border-box;

}

.cat-pdfs::after {
    content: '';
    height: 70%;
    width: 70%;
    border: 10px solid #ff914d;
    position: absolute;
    z-index: 2;
    background-color: transparent;
}

.cat-pdfs {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;

}


.cat-pdfs>div {
    background-color: #f36d21;
    height: 40%;
    width: 25%;
    margin: 2%;
    min-height: 11rem;
    min-width: 10rem;
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-rows: 30% 70%;
    place-items: center start;
    color: white;
    font-size: 1.3rem;
    padding-left: 5%;
    box-sizing: border-box;
    font-weight: 700;
    letter-spacing: 2px;
    transition: all .2s;
}



.cat-pdfs>div>div {
    height: 100%;
    width: 100%;
    background-color: rgb(43, 43, 43);
    box-sizing: border-box;

}

.cat-right {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
}

.cat-right>img {
    height: 90%;
    width: 90%;
  
}

#wire {
    height: 100%;
    width: 100%;
    background: url('../Images/WIRE.jfif');
    background-size: cover;
    background-position: center;
}

#switch {
    height: 100%;
    width: 100%;
    background: url('../Images/SWITCHES.jfif');
    background-size: cover;
    background-position: bottom;
}

#light {
    height: 100%;
    width: 100%;
    background: url('../Images/LIGHTS.jfif');
    background-size: cover;
    background-position: center;
}
#concield {
    height: 100%;
    width: 100%;
    background: url('https://res.cloudinary.com/djnjmmgu8/image/upload/v1713425140/CONCIELD/CN001_xvbwuw.png');
    background-size: cover;
    background-position: center;
    background-color: rgb(207, 207, 207);
}
#gate {
    height: 100%;
    width: 100%;
    background: url('https://res.cloudinary.com/djnjmmgu8/image/upload/v1712725449/GATE/38_cgggxu.png');
    background-size: cover;
    background-position: center;
    background-color: rgb(207, 207, 207);

}



@media screen and (max-width:800px) {
    .catelog {
        height: 177dvh;
        flex-direction: column;

    }

    .cat-right {
        width: 100%;
        height: 40%;
        box-sizing: border-box;
        overflow: hidden;

    }

    .cat-left {
        width: 100%;
        height: 60%;
        place-items: center;

    }

    .cat-pdfs {
        display: flex;
        flex-wrap: wrap;
    }

    .cat-right>img {
        height: 90%;
        width: 90%;
    }

}

@media screen and (max-width:320px) {
    .catelog {
        height: fit-content;
    }

    .cat-right {
        width: 100%;
        height: 30%;
    }

    .cat-left {
        width: 100%;
        height: 70%;
        place-items: center;

    }

    .cat-pdfs>div {
        height: 40%;
        width: 25%;
        min-height: 11rem;
        min-width: 10rem;
        max-height: 11rem;
        max-width: 10rem;
        position: relative;
        z-index: 3;
        display: grid;
        grid-template-rows: 30% 70%;
        place-items: center start;
        font-size: 1.3rem;
        padding-left: 2%;
        box-sizing: border-box;
        font-weight: 700;
        letter-spacing: 2px;
        transition: all .2s;
    }
}
@media screen and (max-width:600px) {
    .catelog {
        height: fit-content;
    }
}