
.app{
    width: 100%;
    overflow: hidden;
    position: relative;
}
.app-block1{
    width: 100%;
    height:100dvh;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow-y: scroll;
}
