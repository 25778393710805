.item-card{
    height: 20rem;
    width:18rem;
    padding: 2%;
    border: 10px solid #f36d21;
    box-shadow: 2px 2px 10px 1px #f36d21;
    box-sizing: border-box;
   margin: 2% 0;
   background-color: rgb(255, 255, 255);
   overflow: hidden;
}
.img-top{
    height:100%;
    width:100%;
    object-fit: contain !important;
    transition: all 1s;
}
.item-card:hover .img-top{
    transform: scale(1.2);
}