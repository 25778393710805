.navbar{
  position: absolute;
  width: 100%;
  backdrop-filter: blur(2px);
  box-sizing: border-box;
  background-color: #F36D21;
  color: rgb(255, 255, 255);
  z-index: 99999;
  top: 0;
}

.navbar-collapse {
  width: 100%;
}
.navbar-brand{
  height: 4rem;
  width: 10%;
  box-sizing: border-box;

}
.navbar-brand>img{
  height: 100%;
  min-height: 4rem;
  min-width: 10rem;
  width: 100%;
  border-radius: 22px 0 22px 0;
  animation: zoom 1s 1 linear;
}
@keyframes zoom {
  from{
    transform: scale(40);
  }
  to{
    transform: scale(1);
  }
}
.navbar-nav {
  width: 100%;
  display: flex;
  justify-content: end;

}
.nav-item{
  margin: 0 2%;
  font-size: 1.2rem;
  font-weight: 500;
  box-sizing: border-box;
}
.nav-link{
  color: rgb(255, 255, 255) !important;
  transition: all 1s;
}
.nav-link:hover{
  color:rgb(226, 226, 226) !important;
  transform: scale(1.2);
}
@media screen and (max-width:991px) {
  .nav-link:hover{
    color:rgb(0, 0, 0) !important;
    transform: scale(1);
  }
}